import React from "react"
import { graphql } from "gatsby"
import LayOut from "../components/layout"
import BlocksContent from "../components/blocksContent"
import HubpostForm from "../components/shared/hubspotForm"
import RecentBlog from "./recent-blogs"
import SEO from "../components/seo"
import { GatsbyImage } from "gatsby-plugin-image"
import "./blog-template.sass"
import { navigate } from "gatsby"
import Link from "@components/shared/link"
import { buttonStyle } from "@util/constants"
import { A, P } from "@util/standard"

import { colors } from "@util/constants"

import styled from "styled-components"

const StyledA = styled(A)`
  color: ${colors.white};
  background-color: ${colors.green};
  margin-right: 10px;
  border-radius: 4px;
  padding: 5px 16px;
  font-weight: 400;
  width: fit-content;
  :hover {
    ${({ theme }) =>
      theme &&
      `color:${colors.green}; background-color:${colors.white}; border:1px solid ${colors.green} `};
  }
`

const ResourceTemplate = ({ data }) => {
  const {
    allSanityResource: {
      edges: [{ node: blog }],
    },
  } = data

  const onClick = e => {
    e.preventDefault()
    navigate(-1)
  }

  return (
    <LayOut>
      <SEO
        title={blog.title || ""}
        description={blog.excerpt || ""}
        image={data.image || null}
        url={`/${blog.slug.current}`}
        post="article"
      />
      <div id="blog_template">
        <div className="container">
          <span>{blog.publishdate || ""}</span>
          <h2>{blog.title || ""}</h2>
          <a onClick={onClick}>&lt; Back</a>
          <div className="content">
            <div className="main-content">
              <GatsbyImage
                image={blog.featureImage.asset.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                alt={blog.title}
                className="featureBlogImage"
              />
              {blog.content && (
                <BlocksContent blocks={blog.content._rawColumnContent} />
              )}
              {blog.downloads && blog.downloads.length > 0 && (
                <>
                  <hr />
                  <h4>Downloadable Resources</h4>
                  {blog.downloads.map(download => {
                    return (
                      <StyledA
                        href={`${download.download.asset.url}?dl=1`}
                        theme={buttonStyle["green"]}
                      >
                        {download.title}
                      </StyledA>
                    )
                  })}
                </>
              )}
              {blog.hubspotForm && (
                <>
                  <BlocksContent
                    blocks={blog.hubspotForm.richText._rawColumnContent}
                  />
                  <HubpostForm hubspotFormID={blog.hubspotForm.formID} />
                </>
              )}

              {blog.authorName && (
                <p>
                  <strong>Written by {blog.authorName}</strong>
                </p>
              )}
            </div>

            <div className="side-bar">
              <RecentBlog currentSlug={blog.slug.current || ""} />
            </div>
          </div>
        </div>
      </div>
    </LayOut>
  )
}

export const query = graphql`
  query test($slug: String) {
    allSanityResource(filter: { slug: { current: { eq: $slug } } }) {
      edges {
        node {
          title
          slug {
            current
          }
          publishdate(formatString: "MMM DD, YYYY")
          authorName
          featureImage {
            asset {
              url
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
          excerpt
          content {
            _rawColumnContent(resolveReferences: { maxDepth: 5 })
          }
          downloads {
            title
            description
            download {
              asset {
                url
              }
            }
          }
          hubspotForm {
            richText {
              _rawColumnContent
            }
            formID
          }
        }
      }
    }
  }
`

export default ResourceTemplate
