import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql, Link } from "gatsby"
import MyLink from "../components/myLink"
import { v4 as uuid } from "uuid"
import "./recent-blogs.sass"

const RecentBlog = ({ currentSlug }) => {
  const {
    allSanityBlogs: { edges: data },
  } = useStaticQuery(graphql`
    query recentBlogQuery {
      allSanityBlogs(sort: { fields: publishdate, order: DESC }, limit: 4) {
        edges {
          node {
            title
            publishdate(formatString: "MMM DD, YYYY")
            featureImage {
              asset {
                url
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: NONE
                  formats: [AUTO, WEBP]
                )
              }
            }
            excerpt
            link {
              internallink
              linktext
              newwindow
              url
            }
            sanityInternal
            slug {
              current
            }
          }
        }
        totalCount
      }
    }
  `)

  return (
    <div id="recent_blog">
      <h3>Recent Posts</h3>
      {/* useStaticQuery cannot accept variable, for filtering out the current blog user views, display 3 items */}
      {data
        .filter(({ node: blog }) => {
          if (!blog.slug) return true
          if (blog.slug.current !== currentSlug) return true
          return false
        })
        .slice(0, 3)
        .map(({ node: blog }) => (
          <div className="recent-blog" key={uuid()}>
            <Link to={`/blog/${blog.slug.current}/`} className="recentBlogLink">
              {blog.featureImage ? (
                <GatsbyImage
                  image={blog.featureImage.asset.gatsbyImageData}
                  alt={blog.featureImage.asset.originalFilename}
                />
              ) : (
                <div className="feature-image-place-holder"></div>
              )}
            </Link>
            <h4>{blog.title}</h4>
            <p>{blog.excerpt}</p>
            {blog.sanityInternal ? (
              <Link
                to={`/blog/${blog.slug.current}/`}
                className="theme-blue-btn"
              >
                Read More
              </Link>
            ) : (
              <MyLink {...blog.link} className="theme-blue-btn other-pages" />
            )}
          </div>
        ))}
    </div>
  )
}

export default RecentBlog
